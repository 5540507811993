.fun-stuff {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-weight: bold;
  color: var(--text-color);
}

.tab.active {
  background-color: #0066cc;
  color: white;
}

.tab-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 20px;
  background-color: transparent;
} 