:root {
  --bg-color: #ffffff;
  --text-color: #333333;
  --nav-bg: #333;
  --nav-text: #ffffff;
}

[data-theme='dark'] {
  --bg-color: #1a1a1a;
  --text-color: #ffffff;
  --nav-bg: #121212;
  --nav-text: #ffffff;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: all 0.3s ease;
}

.App-content {
  padding: 20px;
  flex: 1;
}

nav {
  background-color: var(--nav-bg);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-brand a {
  color: var(--nav-text);
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-brand a:hover {
  color: #61dafb;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 2rem;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

nav a {
  color: var(--nav-text);
  text-decoration: none;
  font-size: 1.1rem;
}

nav a:hover {
  color: #61dafb;
}

.theme-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: transform 0.3s ease;
  color: var(--nav-text);
}

.theme-toggle:hover {
  transform: scale(1.1);
}

.blog-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.blog-post {
  background: var(--bg-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.blog-post:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.blog-post-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.blog-post h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  color: var(--text-color);
}

.blog-post time {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}

.preview-content {
  flex-grow: 1;
  margin-bottom: 1rem;
}

.read-more {
  display: inline-block;
  color: #0066cc;
  text-decoration: none;
  font-weight: 500;
  margin-top: auto;
  padding: 0.5rem 0;
}

.read-more:hover {
  color: #0052a3;
}

/* Dark theme adjustments */
[data-theme='dark'] .blog-post {
  border-color: rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
}

[data-theme='dark'] .blog-post time {
  color: #999;
}

[data-theme='dark'] .read-more {
  color: #66b3ff;
}

[data-theme='dark'] .read-more:hover {
  color: #99ccff;
}

[data-theme='dark'] .blog-post:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Full blog post styles */
.blog-post-full {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.blog-post-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.back-button {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  background: none;
  border: none;
  color: #0066cc;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
}

.back-button:hover {
  color: #0052a3;
}

[data-theme='dark'] .back-button {
  color: #66b3ff;
}

[data-theme='dark'] .back-button:hover {
  color: #99ccff;
}

.blog-content {
  line-height: 1.6;
  margin-top: 2rem;
}

.blog-content h1,
.blog-content h2,
.blog-content h3,
.blog-content h4,
.blog-content h5,
.blog-content h6 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.blog-content p {
  margin-bottom: 1.2em;
}

.blog-content a {
  color: #0066cc;
  text-decoration: none;
}

.blog-content a:hover {
  text-decoration: underline;
}

.blog-content ul,
.blog-content ol {
  margin: 1em 0;
  padding-left: 2em;
}

.blog-content li {
  margin-bottom: 0.5em;
}

.blog-content blockquote {
  border-left: 4px solid #ddd;
  margin: 1em 0;
  padding-left: 1em;
  color: #666;
}

.blog-content code {
  background-color: #f5f5f5;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
}

.blog-content pre {
  background-color: #f5f5f5;
  padding: 1em;
  border-radius: 5px;
  overflow-x: auto;
}

[data-theme='dark'] .blog-content code,
[data-theme='dark'] .blog-content pre {
  background-color: #2d2d2d;
}

[data-theme='dark'] .blog-content blockquote {
  border-left-color: #444;
}

.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.home-intro {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: var(--text-color);
}

.blog-list-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.blog-list {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.blog-list-item {
  padding: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.blog-list-item:last-child {
  border-bottom: none;
}

.blog-list-item h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.blog-list-item time {
  display: block;
  color: #666;
  margin-bottom: 1.5rem;
}

[data-theme='dark'] .blog-list-item {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

[data-theme='dark'] .blog-list-item time {
  color: #999;
}

/* Contact page styles */
.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.contact-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: start;
}

.contact-info {
  padding-right: 2rem;
}

.contact-info h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.contact-methods {
  margin-top: 2rem;
}

.contact-method {
  margin-bottom: 2rem;
}

.contact-method h3 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.email-link {
  color: #0066cc;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.email-link:hover {
  color: #0052a3;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-links a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.social-icon {
  font-size: 1.2rem;
}

.social-links a:hover {
  background: var(--text-color);
  color: var(--bg-color);
}

.contact-form {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
}

.form-group {
  margin-bottom: 1.5rem;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background: var(--bg-color);
  color: var(--text-color);
  transition: all 0.3s ease;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #0066cc;
  box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background: #0066cc;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
  margin: 0 auto;
}

.submit-button:hover {
  background: #0052a3;
}

/* Dark theme adjustments */
[data-theme='dark'] .social-links a {
  background: rgba(255, 255, 255, 0.1);
}

[data-theme='dark'] .social-links a:hover {
  background: var(--text-color);
  color: var(--bg-color);
}

[data-theme='dark'] .contact-form {
  background: rgba(255, 255, 255, 0.05);
}

[data-theme='dark'] .email-link {
  color: #66b3ff;
}

[data-theme='dark'] .email-link:hover {
  color: #99ccff;
}

/* Responsive design */
@media (max-width: 768px) {
  .contact-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .contact-info {
    padding-right: 0;
  }
}

/* About page styles */
.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.about-content {
  max-width: 900px;
  margin: 0 auto;
}

.about-content h1 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--text-color);
}

.about-grid {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 2fr;
  gap: 3rem;
  align-items: start;
}

.about-image {
  position: sticky;
  top: 2rem;
}

.profile-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--text-color);
}

.about-text p {
  margin-bottom: 1.5rem;
}

.about-text a {
  color: #0066cc;
  text-decoration: none;
}

.about-text a:hover {
  text-decoration: underline;
}

/* Dark theme adjustments */
[data-theme='dark'] .about-text a {
  color: #66b3ff;
}

[data-theme='dark'] .profile-image {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

/* Responsive design */
@media (max-width: 768px) {
  .about-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .about-image {
    position: static;
    max-width: 300px;
    margin: 0 auto;
  }
}

.form-error {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.form-success {
  color: #28a745;
  background-color: rgba(40, 167, 69, 0.1);
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Fun Stuff Styles */
.fun-stuff-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.fun-stuff-title {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--text-color);
}

.fun-tabs {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.tab-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.05);
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-button.active {
  background: #0066cc;
  color: white;
}

.tab-content {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 12px;
  min-height: 400px;
}

.code-snippet {
  background: rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.code-snippet pre {
  background: var(--bg-color);
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
}

.book-card, .playlist-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.book-comment {
  font-style: italic;
  margin-top: 0.5rem;
}

/* Dark theme adjustments */
[data-theme='dark'] .tab-button {
  background: rgba(255, 255, 255, 0.1);
}

[data-theme='dark'] .tab-button.active {
  background: #66b3ff;
}

[data-theme='dark'] .code-snippet {
  background: rgba(255, 255, 255, 0.05);
}

/* Add mobile-specific styles at the bottom */
@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  .mobile-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--nav-bg);
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-around;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }

  .mobile-nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: var(--nav-text);
    font-size: 0.75rem;
    opacity: 0.7;
    transition: opacity 0.3s ease;
    background: none;
    border: none;
    cursor: pointer;
  }

  .mobile-nav-link.active {
    opacity: 1;
  }

  .mobile-nav-icon {
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
  }

  .App-content {
    padding-bottom: 5rem;
  }
}
